// TODO: REFACTORING
import React, { useState } from 'react';
// import { navigate } from '@reach/router';
import { navigate } from 'gatsby';

import * as Styled from './CustomCharterOfferCard.style';

const CustomCharterOfferCard = ({ data }) => {
  const [clientXonMouseDown, setClientXonMouseDown] = useState(0);
  const [clientYonMouseDown, setClientYonMouseDown] = useState(0);

  const handleNavigate = () => {
    navigate(data.link);
  };

  const handleOnMouseDown = e => {
    setClientXonMouseDown(e.clientX);
    setClientYonMouseDown(e.clientY);
    e.preventDefault(); // stops weird link dragging effect
  };

  const handleOnClick = e => {
    e.stopPropagation();
    if (clientXonMouseDown !== e.clientX || clientYonMouseDown !== e.clientY) {
      // prevent link click if the element was dragged
      e.preventDefault();
    } else {
      handleNavigate();
    }
  };

  return (
    <Styled.CardWrapper
      onClick={e => handleOnClick(e)}
      onMouseDown={e => handleOnMouseDown(e)}
    >
      <Styled.Card>
        <Styled.CardImageWrapper>
          <Styled.Image src={data.image} alt={data.imageAlt} />
        </Styled.CardImageWrapper>
        <Styled.Subtitle>{data.subtitle}</Styled.Subtitle>
        <Styled.Title>{data.title}</Styled.Title>
        <Styled.Description>{data.description}</Styled.Description>
      </Styled.Card>
    </Styled.CardWrapper>
  );
};

export default CustomCharterOfferCard;
