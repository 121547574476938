import styled from 'styled-components';

export const CardWrapper = styled.a`
  text-decoration: none;
`;

export const Card = styled.div`
  width: calc((100vw - var(--container-size)) / 4);
  max-width: 600px;
  padding-right: 20px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;

  @media (min-width: 1024px) {
    margin-right: 16px;

    &:last-of-type {
      margin: 0;
    }
  }

  @media (max-width: 1445px) {
    width: calc((100vw - var(--container-size)) / 3);
  }

  @media (max-width: 1024px) {
    width: calc((100vw - var(--container-size)) / 2);
  }

  @media (max-width: 768px) {
    width: calc((100vw - var(--container-size)));
  }
`;

export const CardImageWrapper = styled.div`
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 8px;

  @media (min-width: 1024px) {
    position: relative;
    height: 187px;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 71px;
      background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
      border-radius: 0 0 8px 8px;
    }
  }
`;

export const Image = styled.img`
  height: 240px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.2);
  }

  @media (min-width: 1024px) {
    height: 187px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  color: #2c2c2c;
  font-size: 17px;
  margin-bottom: 2px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

export const Subtitle = styled.p`
  font-weight: 700;
  color: #009688;
  font-size: 12px;
  margin-bottom: 2px;
  text-transform: uppercase;
`;

export const Description = styled.p`
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 0;
  color: #000;

  @media (min-width: 1024px) {
  }
`;
