import { getFlightSearchUrl, getFlightDetailUrl } from '@utils/search';
import { dateToCardFormat, dateToHour, minutesToHours } from '@utils/dates';
import { formatCurrency } from '@utils/currency';
import { useCurrentLang } from '@utils/translate';
import { solveIataOaci } from '@utils/spots';
import aircraftNameHelper from '@utils/aircraftNameHelper';

/**
 * Checks if is a valid item
 *
 * @param {object} item Flight object
 */
const isValidCustomOfferItem = item => {
  const isValid = !!(
    (item.originCity || item.originSpot) &&
    (item.destinationCity || item.destinationSpot)
  );

  return isValid;
};

/**
 * Returns a mapped flight item (shared card)
 *
 * @param {object} item Flight object
 * @param {string} targetRoute Route to redirect (search, detail)
 */
const mapSectionSharedFlightsItem = ({
  item,
  targetRoute = 'search',
  currency = 'BRL',
}) => {
  return {
    image: item.imageUrl || item.destinationCity.imageUrl,
    imageAlt: item.title,
    title: item.title,
    subtitle: item.subtitle || item.destinationCity.name,
    price: formatCurrency({ value: item.startingPrice, currency: currency }),
    originalPrice: item.startingPrice,
    currency: currency,
    link:
      targetRoute === 'search'
        ? getFlightSearchUrl(
            item,
            'flights'
            //item.nextFlight && item.nextFlight.aircraft.id == 849 ? 'eve=1' : ''
          )
        : getFlightDetailUrl(item),
  };
};

/**
 * Returns a mapped route flight item (route shared card)
 *
 * @param {object} item Flight object
 * @param {string} targetRoute Route to redirect (search, detail)
 */
const mapRouteSharedFlightsItem = ({ item, targetRoute = 'search' }) => {
  return {
    image: item.destinationCity.imageUrl,
    imageAlt: item.destinationCity.name,
    title: item.destinationCity.name,
    subtitle: item.destinationCity.description,
    link:
      targetRoute === 'search'
        ? getFlightSearchUrl(item, 'flights', '')
        : getFlightDetailUrl(item),
  };
};

/**
 * Returns a mapped flight item (shared)
 *
 * @param {object} item Flight object
 */
const mapSharedFlightsItem = (item, currency = 'BRL') => {
  const departureHint = solveIataOaci(item.originSpot, 'only-once');
  const arrivalHint = solveIataOaci(item.destinationSpot, 'only-once');
  const lang = useCurrentLang();
  const language = parseLangType(lang);

  return {
    name: aircraftNameHelper(item.aircraft),
    imageUrl: item.aircraft.highlightImageUrl
      ? item.aircraft.highlightImageUrl
      : item.aircraft.model.highlightImageUrl,

    leaveOn: dateToCardFormat(item.flightDatetime, language),
    departureTime: dateToHour(item.departureDatetime),
    arrivalTime: dateToHour(item.arrivalDatetime),
    duration: minutesToHours(item.estimatedTime),

    departure: item.originSpot.name,
    departureHint: departureHint,
    departureCity: item.originSpot.address.city.name,

    arrival: item.destinationSpot.name,
    arrivalHint: arrivalHint,
    arrivalCity: item.destinationSpot.address.city.name,

    price: formatCurrency({ value: item.startingPrice, currency: currency }),
    link: getFlightDetailUrl(item),
  };
};

/**
 * Returns a mapped flight item (charter)
 *
 * @param {object} item Flight object
 */
const mapCustomOffersItem = item => {
  return {
    image: item.imageUrl,
    imageAlt: item.title,
    title: item.title,
    subtitle: item.subtitle,
    description: item.description,
    link: getFlightSearchUrl(item, 'charter'),
  };
};

/**
 * Returns the feed's URL
 *
 * @param {string} url Feed's base url
 * @param {object} params Params for query string
 */
const getFeedUrl = (url, params) => {
  const qParams = params
    .map(param => {
      return param.name && param.value ? `${param.name}=${param.value}` : '';
    })
    .join('&');
  const qs = `?type=landingWebApp&${qParams}`;

  return `${url}${qs}`;
};

export {
  isValidCustomOfferItem,
  mapSectionSharedFlightsItem,
  mapRouteSharedFlightsItem,
  mapSharedFlightsItem,
  mapCustomOffersItem,
  getFeedUrl,
};
