/**
 * Helper function to retrive the aircraft model name
 *
 * @param {object} model
 * @returns string
 */
const aircraftModelNameHelper = model => {
  if (model.name != null && model.name.length > 0) {
    return model.name;
  } else {
    const manufacturer =
      (model.producer && model.producer.name) || model.manufacturer;
    const modelName = `${manufacturer} ${model.model}`;

    return modelName;
  }
};

module.exports = aircraftModelNameHelper;
